var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.model === false)?_c('div',[_c('div',{staticClass:"totalmakeHole"},[_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"screen1"},[_c('headtip',{on:{"showDialog":_vm.showPartner}}),_c('div',{staticClass:"layer2"},[_c('div',{staticClass:"title1"},[_vm._v("Oil casing damage logging technology ")]),_c('div',{staticClass:"title2"},[_vm._v("Oil casing damage logging technology is an integrated evaluation method that combines multi-arm caliper logging with electromagnetic flaw detection techniques. ")]),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('Geological Engineering GPTs')}}},[_vm._v(" Product Inquiry ")])]),_c('div',{staticClass:"layer3"})],1),_vm._m(0),_c('div',{ref:"consultMove",staticClass:"screenGpts"},[_vm._m(1)]),_c('div',{ref:"consultMove",staticClass:"screenGpts1",staticStyle:{"background-color":"white"}},[_vm._m(2),_vm._m(3)]),_c('bottomTip')],1),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)]):_c('div',[_c('div',{staticClass:"totalmakeHoleModel"},[_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"screen1"},[_c('div',{staticStyle:{"margin":"0 15px"}},[_c('headtip',{on:{"showDialog":_vm.showPartner}})],1),_vm._m(4),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('Geological Engineering GPTs')}}},[_vm._v(" Product Inquiry ")]),_c('div',{staticClass:"layer3"})]),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{ref:"bottom"},[_c('bottomTip')],1)]),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("Oil casing damage logging technology")]),_c('div',{staticClass:"heightLight2",staticStyle:{"height":"auto","margin-top":"2.5vh"}},[_c('p',[_vm._v("Oil casing damage logging technology is an integrated evaluation method that combines multi-arm caliper logging with electromagnetic flaw detection techniques. The multi-arm caliper logging provides a direct image of internal corrosion, while the electromagnetic flaw detection measures external corrosion and the remaining thickness of multiple casing strings. This combination enhances the accuracy and comprehensiveness of casing damage detection.")]),_c('p',[_vm._v("The multi-arm caliper logging technology uses a mechanical structure with multiple independent caliper arms evenly distributed along the inner wall of the casing to measure the internal diameter (radius) of the casing. These caliper arms convert the radial variations of the casing's inner wall into changes in potential difference or frequency signals, which are processed, encoded, and transmitted to the surface system. The data is then reconstructed into a three-dimensional profile or image of the casing.")]),_c('p',[_vm._v("Electromagnetic flaw detection logging technology utilizes the principle of electromagnetic induction, where a transmitter coil generates a stable magnetic field within the casing, and a receiver coil detects changes in the induced electromotive force (EMF). When defects such as holes or cracks are present in the casing, they disrupt the induced current loop, leading to variations in the amplitude of the induced EMF. These variations allow for the assessment of changes in casing wall thickness and the identification of damage.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro3"},[_c('div',{staticClass:"grand2"},[_c('div',{staticClass:"back",staticStyle:{"background-color":"#bfd8f9"}},[_c('div',{staticClass:"photo",staticStyle:{"background-color":"white","padding":"1vh 0.5vw","width":"60%"}},[_c('img',{staticStyle:{"border-top-left-radius":"9px","border-top-right-radius":"9px","height":"45vh"},attrs:{"loading":"lazy","src":require("@/assets/casingdamage1.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title1"},[_vm._v("Scope of application:")]),_c('div',{staticClass:"title2"},[_c('li',[_vm._v("Wells that fail pressure testing, workover wells, and wells with highly corrosive fluids.")])]),_c('div',{staticClass:"title1"},[_vm._v("Features and Advantages")]),_c('div',{staticClass:"title2"},[_c('li',[_vm._v("Capable of accurately measuring changes in casing inner diameter, including deformation, bending, diameter reduction, fractures, holes, and internal wall corrosion.")]),_c('li',[_vm._v("Able to identify complex casing shapes such as bending and misalignment, and provide cross-sectional images of the casing at any depth.")]),_c('li',[_vm._v("Capable of detecting wall thickness variations in double-layer or even multi-layer casings, unaffected by internal fluids, wax deposition, or scale.")]),_c('li',[_vm._v("Able to accurately detect both longitudinal and transverse cracks, enhancing the accuracy and reliability of the inspection.")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","margin":"0 auto"}},[_c('div',{staticStyle:{"display":"flex","margin":"0 auto 0 auto","text-align":"center"}},[_c('div',{staticClass:"title"},[_vm._v(" Application situation ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro3"},[_c('div',{staticClass:"light"},[_vm._v(" Oil casing damage logging technology is primarily applied during the mid to late stages of oilfield development when casing damage becomes more severe. By using multi-parameter combination logging, the extent of casing damage is assessed, and the causes of the damage are analyzed. This technology has been widely used in major oilfields and plays a crucial role in guiding the implementation of appropriate measures to ensure the long-term development of the oilfield. ")]),_c('div',{staticClass:"grand"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"photo"},[_c('img',{staticStyle:{"border-top-left-radius":"9px","border-top-right-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/casingdamage2.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v("Data acquisition software curve display interface ")])])]),_c('div',{staticClass:"back"},[_c('div',{staticClass:"photo"},[_c('img',{staticStyle:{"border-top-left-radius":"9px","border-top-right-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/casingdamage3.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v("Multilayer string interpretation software ")])])]),_c('div',{staticClass:"back"},[_c('div',{staticClass:"photo"},[_c('img',{staticStyle:{"border-top-left-radius":"9px","border-top-right-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/casingdamage4.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2"},[_vm._v("3D image of the perforation segment ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layer2"},[_c('div',{staticClass:"title1"},[_vm._v("Oil casing damage logging technology")]),_c('div',{staticClass:"title2"},[_c('p',[_vm._v("Oil casing damage logging technology is an integrated evaluation method that combines multi-arm caliper logging with electromagnetic flaw detection techniques. The multi-arm caliper logging provides a direct image of internal corrosion, while the electromagnetic flaw detection measures external corrosion and the remaining thickness of multiple casing strings. This combination enhances the accuracy and comprehensiveness of casing damage detection.")]),_c('p',[_vm._v("The multi-arm caliper logging technology uses a mechanical structure with multiple independent caliper arms evenly distributed along the inner wall of the casing to measure the internal diameter (radius) of the casing. These caliper arms convert the radial variations of the casing's inner wall into changes in potential difference or frequency signals, which are processed, encoded, and transmitted to the surface system. The data is then reconstructed into a three-dimensional profile or image of the casing.")]),_c('p',[_vm._v("Electromagnetic flaw detection logging technology utilizes the principle of electromagnetic induction, where a transmitter coil generates a stable magnetic field within the casing, and a receiver coil detects changes in the induced electromotive force (EMF). When defects such as holes or cracks are present in the casing, they disrupt the induced current loop, leading to variations in the amplitude of the induced EMF. These variations allow for the assessment of changes in casing wall thickness and the identification of damage.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("Scope of application ")]),_c('div',{staticClass:"heightLight2"},[_vm._v(" Wells that fail pressure testing, workover wells, and wells with highly corrosive fluids. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background":"transparent"}},[_c('div',{staticClass:"heighLight"},[_vm._v(" Features and Advantages ")]),_c('div',{staticClass:"heightLight2"},[_c('li',[_vm._v("Capable of accurately measuring changes in casing inner diameter, including deformation, bending, diameter reduction, fractures, holes, and internal wall corrosion.")]),_c('li',[_vm._v("Able to identify complex casing shapes such as bending and misalignment, and provide cross-sectional images of the casing at any depth.")]),_c('li',[_vm._v("Capable of detecting wall thickness variations in double-layer or even multi-layer casings, unaffected by internal fluids, wax deposition, or scale.")]),_c('li',[_vm._v("Able to accurately detect both longitudinal and transverse cracks, enhancing the accuracy and reliability of the inspection.")])]),_c('div',{staticStyle:{"width":"100%","height":"fit-content","text-align":"center"}},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/casingdamage1.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("Application situation ")]),_c('div',{staticClass:"heightLight2"},[_vm._v(" Oil casing damage logging technology is primarily applied during the mid to late stages of oilfield development when casing damage becomes more severe. By using multi-parameter combination logging, the extent of casing damage is assessed, and the causes of the damage are analyzed. This technology has been widely used in major oilfields and plays a crucial role in guiding the implementation of appropriate measures to ensure the long-term development of the oilfield. ")]),_c('div',{staticClass:"pro2"},[_c('div',{staticClass:"grand2"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"photo",staticStyle:{"background-color":"white","padding":"1vh 0.5vw"}},[_c('img',{staticStyle:{"border-top-left-radius":"9px","border-top-right-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/casingdamage2.png")}})]),_c('div',{staticClass:"title1"},[_vm._v("Data acquisition software curve display interface ")])])]),_c('div',{staticClass:"grand2"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"photo",staticStyle:{"background-color":"white","padding":"1vh 0.5vw"}},[_c('img',{staticStyle:{"width":"80%","border-top-left-radius":"9px","border-top-right-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/casingdamage3.png")}})]),_c('div',{staticClass:"title1"},[_vm._v("Multilayer string interpretation software ")])])]),_c('div',{staticClass:"grand2"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"photo",staticStyle:{"background-color":"white","padding":"1vh 0.5vw"}},[_c('img',{staticStyle:{"width":"80%","border-top-left-radius":"9px","border-top-right-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/casingdamage4.png")}})]),_c('div',{staticClass:"title1"},[_vm._v("3D image of the perforation segment ")])])])])])
}]

export { render, staticRenderFns }