<template>
  <div>
    <div v-if="model === false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Integrated production technology services and supporting technologies
              </div>
              <div class="title2">It provides one-stop technical services for oil and gas reservoir stimulation from
                plan to on-site implementation, and is a comprehensive platform to provide stimulation technology for
                the global emerging oil and gas market. Anton Oil provides precise stimulation solutions for
                conventional and unconventional oil and gas reservoirs; Supporting professional equipment, customized
                chemical tools, characteristic chemicals, from the program formulation, on-site implementation,
                post-program evaluation, real-time monitoring of production stage and wellbore treatment to achieve the
                whole life cycle of oil and gas well technical services.
              </div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenGpts" style="background-color: white" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
              </div>
            </div>
            <div class="pro3">
              <!--油田-->
              <div class="grand2" @click="goPage('fullscale')">
                <div class="back "  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/subordinate/special1.png" />
                  </div>
                  <div class="title1">Unconventional Reservoirs Full Scale Fracture-Controlled Fracking Technology
                  </div>
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="goPage('design')">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/subordinate/special2.png"/></div>
                  <div class="title1">Technical Services for Stimulation Design & Evaluation

                  </div>

                </div>
              </div>
              <div class="grand2" @click="goPage('stimulation')">
                <div class="back"  style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy" style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/subordinate/special3.jpg"/></div>
                  <div class="title1">Oil & Gas Reservoir Stimulation Service



                  </div>
                </div>
              </div>

              <div class="grand2" @click="goPage('acidizing')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="width:80%;border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/subordinate/special4.png" /></div>
                  <div class="title1">Acidizing (Acid Fracturing) Services

                  </div>
                </div>
              </div>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 4vw;">
              <headtip @showDialog="showPartner"></headtip>
            </div>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->
            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Integrated production technology services and supporting technologies</div>
              <div class="title2">It provides one-stop technical services for oil and gas reservoir stimulation from
                plan to on-site implementation, and is a comprehensive platform to provide stimulation technology for
                the global emerging oil and gas market. Anton Oil provides precise stimulation solutions for
                conventional and unconventional oil and gas reservoirs; Supporting professional equipment, customized
                chemical tools, characteristic chemicals, from the program formulation, on-site implementation,
                post-program evaluation, real-time monitoring of production stage and wellbore treatment to achieve the
                whole life cycle of oil and gas well technical services.
              </div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screen2">
            <div class="pro2">
              <!-- 非常规油气藏全尺度缝控压裂技术服务 -->
              <div class="grand2" @click="goPage('fullscale')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/subordinate/special1.png"
                      style="border-top-right-radius: 9px;border-top-left-radius: 9px" />
                  </div>
                  <div class="title1">Unconventional Reservoirs Full Scale Fracture-Controlled Fracking Technology
                  </div>

                </div>
              </div>
              <!--增产设计及评价技术服务-->
              <div class="grand2" @click="goPage('design')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/subordinate/special2.png"
                      style="border-top-right-radius: 9px;border-top-left-radius: 9px" />
                  </div>
                  <div class="title1">Technical Services for Stimulation Design & Evaluation
                  </div>

                </div>
              </div>
              <!--油气井增产技术服务-->
              <div class="grand2" @click="goPage('stimulation')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/subordinate/special3.jpg"
                      style="border-top-right-radius: 9px;border-top-left-radius: 9px" />
                  </div>
                  <div class="title1">Oil & Gas Reservoir Stimulation Service
                  </div>

                </div>
              </div>
              <!-- 酸化（酸压）技术服务 -->
              <div class="grand2" @click="goPage('acidizing')">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo">
                    <img loading="lazy" src="@/assets/subordinate/special4.png"
                      style="border-top-right-radius: 9px;border-top-left-radius: 9px" />
                  </div>
                  <div class="title1">Acidizing (Acid Fracturing) Services
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import { shareUrl } from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: { PartnerTip, consultTip, headtip, bottomTip },

  data() {
    return {
      title: '',
      pageTitle: 'Integrated production technology services and supporting technologies',
      pageDescription: 'It provides one-stop technical services for oil and gas reservoir stimulation from plan to on-site implementation, and is a comprehensive platform to provide stimulation technology for the global emerging oil and gas market. Anton Oil provides precise stimulation solutions for conventional and unconventional oil and gas reservoirs; Supporting professional equipment, customized chemical tools, characteristic chemicals, from the program formulation, on-site implementation, post-program evaluation, real-time monitoring of production stage and wellbore treatment to achieve the whole life cycle of oil and gas well technical services.',
      pageKeywords: 'Integrated production technology services and supporting technologies,,Geological Engineering GPTs',
      // model:false,
      screenWidth: window.innerWidth, // 初始屏幕宽度
      dialogconsult: false,
      dialogVisible: false,

    }

  },

  computed: {
    model() {
      return this.screenWidth <= 700;
    },
  },
  methods: {
    // 更新 `screenWidth` 的值
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;

    },
    clickConsult(item) {
      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    goPage(path) {
      window.open("/" + path)
    },
    embed() {
      window.open("/embed")
    },
    tube() {
      window.open("/tube")
    },
    tdrill() {
      window.open("/tdrill")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    this.updateScreenWidth();
    // 添加窗口大小变化监听器
    window.addEventListener('resize', this.updateScreenWidth);

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle,  //'智能工程迭代优化GPTs-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //'依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords,  // '智能工程迭代优化GPTs,工程地质技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole {
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 2.84301606922126vh 1.5vw 2.84301606922126vh 5vw;
      background-image: url("../../assets/groundHome6.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.4));
      }

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;
        width: 45%;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 5vh;
        }

        .title2 {
          margin-top: 2vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }

        .titlle3 {
          margin-top: 2vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;
      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        row-gap: 2vh;

        .grand2 {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 30vh;
          }


          .title1 {
            padding: 4vh 0;
            opacity: 4;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.71940667490729vh;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 3.5vh;
          }

          .title2 {
            opacity: 4;
            margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
            text-align: justify;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4 {
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 58vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 59.87268232385661vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 78vh;
      padding: 2.84301606922126vh 0 2.84301606922126vh 0;
      background-image: url("../../assets/groundHome6.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      /* Add any additional styling as needed */
      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.4));
      }

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        .title1 {
          width: fit-content;
          text-align: center;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          font-size: 1.2rem;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 2vh auto;
          // text-indent: 2em; 
          width: 90%;
          height: fit-content;
          font-size: 1rem;
          text-align: justify;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 2.6vh;
          word-spacing: 0.1rem;
        }

        .titlle3 {
          margin: 0 auto;
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          border-radius: 56px;
          width: fit-content;
          padding: 10px 4.6875vw;
          height: 5.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;
        }
      }
    }

    .screen2 {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 10px 15px;
      background: transparent;

      .title {
        height: 8.23733003708282vh;
        opacity: 1;
        text-align: center;
        margin: 0 auto;
        font-size: 1.5vw;
        //color:   #3172dd;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 15px auto 0 auto;
        width: 90%;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 22px;

      }

      .pro2 {
        height: fit-content;
        width: 100%;
        z-index: 89156;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;

        .grand2 {
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
          }

          .photo {
            border-radius: 9px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 25vh;
          }

          .title1 {
            padding: 4vh 0;
            opacity: 4;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.71940667490729vh;
            font-size: 0.9rem;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 3.5vh;
            // color: white;
          }

          .title2 {
            opacity: 4;
            margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
            text-align: justify;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }}

      }


    }
  }

}
</style>
