<template>
  <div>
    <div v-if="model === false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering GPTsGPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">A series of techniques for tapping the potential of old wells combined with geological
                engineering
              </div>
              <div class="title2">Based on fine geological research, geological engineering combined with old well
                tapping technology uses physical, chemical and biological technologies/materials as means to increase
                the mobility of formation fluid by removing oil, gas and water flow resistance on the one hand, and
                optimize crude oil flow profile by "plugging" and "adjusting" on the other hand, so as to improve the
                oil and gas production degree within the well control range and fully tap the production potential of
                old wells.</div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
                Product inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenGpts" style="background-color: white" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <div class="title">&nbsp;Water shutoff profile control and flooding technology
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2" @click="rhmodie">
                <div class="back " style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect1.png" />
                  </div>
                  <div class="title1">RH supramolecular multi-functional oil displacement technology
                  </div>
                  <!-- <div class="title2">RH supramolecular multi-functional oil displacement agent is developed for the
                    problems existing in polymer flooding, binary flooding and ternary compound flooding. It has the
                    characteristics of temperature resistance, salt resistance and mechanical shear resistance. Through
                    its own molecular structure, it can realize deep profile control and flooding of reservoirs. It is a
                    highly efficient technology for deep profile control and flooding of reservoirs. It has the
                    characteristics of strong viscosity increasing performance, good viscosity stability, strong salt
                    resistance and good emulsifying solubilization performance.</div> -->

                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="temp">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect2.png" /></div>
                  <div class="title1">High temperature resistance and high strength composite plugging control
                    technology

                  </div>
                  <!-- <div class="title2">High temperature resistant and high strength composite plugging agent adopts
                    inorganic material as main agent and tackifier as system stabilizer to improve stability and
                    pumpability of system; retarder is added to prepare solution for injection into formation; formation
                    heat is utilized to solidify solution in formation to form high temperature resistant concrete-like
                    solid substance, which forms effective plugging in channeling and water outlet channels, reduces
                    water content of oil well and improves final recovery ratio of heavy oil field.</div> -->
                </div>
              </div>
              <div class="grand2" @click="necleus">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect3.png" /></div>
                  <div class="title1">Core-shell anisotropic water-based microspheres profile control and flooding
                    technology

                  </div>
                  <!-- <div class="title2">Core-shell anisotropic water-based microspheres aredevelopedto solvethe problems
                    existing in profile control and flooding of low permeability pore type or pore-fracture type
                    reservoirs. It hasthe characteristics of shell coating core, shell hydrophilic, core particle size
                    nano and oil soluble water insoluble. The microspheres have good deep injectivity, and the shell is
                    dissolved and released by shearing during injection process, and the core is aggregated and blocked
                    when entering water phase, and the core disperses crude oil (washing oil) when entering oil phase,
                    thus having intelligent profile control and displacement effect of "water plugging when encountering
                    water and oil washing when encountering oil".</div> -->

                </div>
              </div>
              <div class="grand2" @click="water">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect4.png" /></div>
                  <div class="title1">Self-assembling water-base microsphere profile control and flooding technology

                  </div>
                  <!-- <div class="title2">Self-assembling water-based microspheres aredevelopedto solvethe problems existing
                    in profile control and flooding of fractured or fractural-porous reservoirs. it has thatstructural
                    characteristic of modified hydrophilic shell + core + emulsion surfactant, and realize selective
                    plugging of high permeability fracture by self-aggregation microspheres, emulsion surfactant
                    emulsion-emulsion aggregation-plugging-liquid depolymerization-emulsion breaking
                    droplet-solubilization and oil washing auxiliary oil displacement effect through controllable
                    self-aggregation plugging mechanism + oil in-situ emulsification to form oil wall auxiliary oil
                    displacement mechanism.</div> -->

                </div>
              </div>
              <div class="grand2" @click="bottom">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect5.png" /></div>
                  <div class="title1">Water shutoff of bottom water reservoir technology
                  </div>
                  <!-- <div class="title2">For bottom water channeling or bottom water coning oil wells caused by excessive
                    production parameters or improper offensive modification measures, selective plugging agent and
                    extrusion plugging agent in original production perforation section are used to seal bottom water
                    channeling or coning. For oil wells with thin oil layer (&lt;5m), clear oil-water interface, bottom
                    water lifting and coning caused by upward movement of oil-water interface, new oil-water interface
                    is determined by numerical simulation, new holes are drilled at appropriate positions above new
                    oil-water interface, and selective plugging agent is squeezed from new holes to form artificial
                    partition. Thereby reducing the water content of the oil well produced liquid and increasing the oil
                    production.</div> -->
                </div>
              </div>
            </div>

          </div>
          <div class="screenGpts" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;Plugging removal for oil, gas and water wells technology

                </div>
              </div>
            </div>

            <div class="pro3">
              <!--油田-->
              <div class="grand2" @click="enzyme">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect6.png" />
                  </div>
                  <div class="title1">Petroleum enzyme plugging removal technology
                  </div>
                  <!-- <div class="title2">Petroleum enzyme is a kind of bio-based material with catalysis, which can
                    significantly restore the flow capacity of formation crude oil. It is an international leading
                    mature product developed by using modern biological engineering technologies such as genetic
                    engineering, cell engineering, and enzyme engineering to mutate and breed new strains. It has the
                    ability to efficiently release crude oil on the surface of solid particles. It has the
                    characteristics of catalytic oil washing activity, changing rock wettability and reducing
                    interfacial tension.</div> -->

                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="liquid">
                <div class="back">
                  <div class="photo"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect7.png" />
                  </div>
                  <div class="title1">Fluid cavitation stimulation and injection enhancement technology



                  </div>
                  <!-- <div class="title2">The fluid cavitation reservoir reconstruction technology is characterized in that
                    oil pipes are connected with a cavitator, pressure waves such as cavitation shock wave,
                    self-vibration wave and acoustic wave generated by the cavitator are excited by high-speed fluid
                    circulation to act on the reservoir and the fluid in the reservoir together, the liquid in the
                    reservoir pores is excited to generate secondary cavitation, high energy generated by cavitation
                    collapse moment is used to act on pores and throats, a large number of micro-fractures are
                    penetrated and created, fatigue fractures are added, the permeability of the reservoir is improved,
                    and the production increase is realized.</div> -->

                </div>
              </div>
              <!--油田-->
              <div class="grand2" @click="gaswell">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect8.png" />
                  </div>
                  <div class="title1">Gas well plugging removal (water lock removal) technology
                  </div>
                  <!-- <div class="title2">Gas well plugging removal technology is to add water blocking agent into the well,
                    which can reduce the interfacial tension between formation water and rock surface, and has certain
                    dissolution ability to inorganic and organic scale. After adding water blocking agent into tubing,
                    shut in well, and rely on external gas source to suppress the agent into formation. The agent forms
                    a low interfacial tension film on the pore surface, reduces the surface tension of formation water,
                    and reduces the minimum flowing pressure difference and exciting pressure difference. Discharge the
                    accumulated liquid near the well area by venting or directly inputting the liquid into the gas
                    station, and maintain good gas-liquid throughput for a period of time.</div> -->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="magnet">
                <div class="back">
                  <div class="photo"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect9.png" />
                  </div>
                  <div class="title1">Giant magnetic viscosity reduction and wax prevention technology
                  </div>
                  <!-- <div class="title2">Under the action of strong magnetic field, the energy exchange of magnetic field
                    is the result of shear force change in the process of medium flow. The greater the magnetic field
                    intensity, the more the converted magnetic field energy, the greater the stress tensor on the
                    surface of oil flow medium, the greater the shear stress tensor formed, and the more the viscosity
                    of crude oil decreases.
                  </div> -->
                </div>
              </div>
              <!--油田-->
              <div class="grand2" @click="pressure">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect10.png" />
                  </div>
                  <div class="title1">
                    Pressure Reduction and Injection Enhancement in High Pressure Underinjection Wells Technology


                  </div>
                  <!-- <div class="title2">In the production process of water injection wells in sandstone reservoirs,
                    physical plugging caused by such factors as chemical plugging, corrosion scaling plugging, water
                    injection quality plugging, clay swelling plugging, mineral migration plugging, carbonate cement
                    plugging, interfacial tension in reservoirs, wettability, low porosity and low permeability, etc.,
                    is the comprehensive cause of high pressure and low injection in water injection wells.</div> -->

                </div>
              </div>
            </div>
          </div>
          <div class="screenGpts" style="background-color: white" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <div class="title">&nbsp;EOR technology

                </div>
              </div>
            </div>
            <div class="pro3" style="grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)">

              <!--油田-->
              <div class="grand2" @click="microorganism">
                <div class="back " style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect11.png" />
                  </div>
                  <div class="title1">Microbial oil recovery technology


                  </div>
                  <!-- <div class="title2">Microbial enhanced oil recovery technology refers to the use of specific
                    functional bacteria in the reservoir, with crude oil as the carbon source for metabolic growth, in
                    the process of producing proliferation of bacteria, while producing biosurfactants, organic acids,
                    gases, enzymes and other active substances, acting on the reservoir, improving the flow capacity of
                    crude oil, adjusting the wettability of the reservoir, degrading colloidal asphaltenes, etc., acting
                    on the residual oil of the reservoir.It can make the reservoir tend to the growth and proliferation
                    of crude oil, greatly delay the action period, change the wettability of rock and reduce the
                    interfacial tension.</div> -->

                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="gas">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect12.png" /></div>
                  <div class="title1">High temperature mixed gas huff and puff Injection and production technology
                  </div>
                  <!-- <div class="title2">Oxygen in the air is used as an oxidant, diesel oil is used as a combustion agent,
                    and deflagration is carried out under high pressure to generate carbon dioxide and nitrogen gas at
                    high temperature and high pressure. Water is mixed with it for heat exchange, water absorbs heat and
                    evaporates to form water vapor, carbon dioxide and nitrogen are cooled to 200-250 degrees Celsius.
                    Finally, the high temperature gas mixture is injected into the oil layer by using its high pressure.
                  </div> -->

                </div>
              </div>
              <div class="grand2" @click="heavyoil">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect13.png" /></div>
                  <div class="title1">Low temperature emulsification and viscosity reduction of super heavy oil wells
                    technology


                  </div>
                  <!-- <div class="title2">Emulsification viscosity reduction technology is to disperse solid and liquid
                    components in heavy oil by using emulsifier and its oxidation process, and to form heavy
                    oil-emulsifier-surfactant ternary system by introducing low molecular weight surfactant into
                    emulsifier and heavy oil, so as to dissolve heavy oil in emulsifier and change viscosity.</div> -->

                </div>
              </div>
              <div class="grand2" @click="dense">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect14.png" /></div>
                  <div class="title1">Nano imbibing agent enhanced oil recovery technology


                  </div>
                  <!-- <div class="title2">Ultra-low permeability, shale/tight reservoirs have poor physical properties,
                    generally are fine pore/very fine throat reservoirs, water is difficult to enter nano-pore throat,
                    swept volume is small, and water flooding recovery is low. Nano-imbibition surfactant has small
                    particle size (nanometer level). After injected into reservoir, it can make formation crude oil
                    (especially residual oil) emulsified, stripped and replaced spontaneously from rock through
                    capillary force to form oil-in-water emulsion mixed phase liquid, which reduces fluid viscosity and
                    flow resistance, thus improving oil displacement efficiency.
                  </div> -->
                </div>
              </div>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <headtip @showDialog="showPartner"></headtip>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       Geological Engineering GPTsGPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1" style="width: 90%;">A series of techniques for tapping the potential of old wells
                combined with geological engineering</div>
              <div class="title2">Based on fine geological research, geological engineering combined with old well
                tapping technology uses physical, chemical and biological technologies/materials as means to increase
                the mobility of formation fluid by removing oil, gas and water flow resistance on the one hand, and
                optimize crude oil flow profile by "plugging" and "adjusting" on the other hand, so as to improve the
                oil and gas production degree within the well control range and fully tap the production potential of
                old wells.</div>
            </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
              Product inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenGpts" style="background-color: white" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <div class="title">&nbsp;Water shutoff profile control and flooding technology
                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2" @click="rhmodie">
                <div class="back " style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect1.png" />
                  </div>
                  <div class="title1">RH supramolecular multi-functional oil displacement technology
                  </div>
                  <!-- <div class="title2">RH supramolecular multi-functional oil displacement agent is developed for the
                    problems existing in polymer flooding, binary flooding and ternary compound flooding. It has the
                    characteristics of temperature resistance, salt resistance and mechanical shear resistance. Through
                    its own molecular structure, it can realize deep profile control and flooding of reservoirs. It is a
                    highly efficient technology for deep profile control and flooding of reservoirs. It has the
                    characteristics of strong viscosity increasing performance, good viscosity stability, strong salt
                    resistance and good emulsifying solubilization performance.</div> -->

                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="temp">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect2.png" /></div>
                  <div class="title1">High temperature resistance and high strength composite plugging control technology
                  </div>
                  <!-- <div class="title2">High temperature resistant and high strength composite plugging agent adopts
                    inorganic material as main agent and tackifier as system stabilizer to improve stability and
                    pumpability of system; retarder is added to prepare solution for injection into formation; formation
                    heat is utilized to solidify solution in formation to form high temperature resistant concrete-like
                    solid substance, which forms effective plugging in channeling and water outlet channels, reduces
                    water content of oil well and improves final recovery ratio of heavy oil field.</div> -->


                </div>
              </div>
              <div class="grand2" @click="necleus">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect3.png" /></div>
                  <div class="title1">Core-shell anisotropic water-based microspheres profile control and flooding
                    technology

                  </div>
                  <!-- <div class="title2">Core-shell anisotropic water-based microspheres aredevelopedto solvethe problems
                    existing in profile control and flooding of low permeability pore type or pore-fracture type
                    reservoirs. It hasthe characteristics of shell coating core, shell hydrophilic, core particle size
                    nano and oil soluble water insoluble. The microspheres have good deep injectivity, and the shell is
                    dissolved and released by shearing during injection process, and the core is aggregated and blocked
                    when entering water phase, and the core disperses crude oil (washing oil) when entering oil phase,
                    thus having intelligent profile control and displacement effect of "water plugging when encountering
                    water and oil washing when encountering oil".</div> -->

                </div>
              </div>
              <div class="grand2" @click="water">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect4.png" /></div>
                  <div class="title1">Self-assembling water-base microsphere profile control and flooding technology

                  </div>
                  <!-- <div class="title2">Self-assembling water-based microspheres aredevelopedto solvethe problems existing
                    in profile control and flooding of fractured or fractural-porous reservoirs. it has thatstructural
                    characteristic of modified hydrophilic shell + core + emulsion surfactant, and realize selective
                    plugging of high permeability fracture by self-aggregation microspheres, emulsion surfactant
                    emulsion-emulsion aggregation-plugging-liquid depolymerization-emulsion breaking
                    droplet-solubilization and oil washing auxiliary oil displacement effect through controllable
                    self-aggregation plugging mechanism + oil in-situ emulsification to form oil wall auxiliary oil
                    displacement mechanism.</div> -->

                </div>
              </div>
              <div class="grand2" @click="bottom">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect5.png" /></div>
                  <div class="title1">Water shutoff of bottom water reservoir technology
                  </div>
                  <!-- <div class="title2">For bottom water channeling or bottom water coning oil wells caused by excessive
                    production parameters or improper offensive modification measures, selective plugging agent and
                    extrusion plugging agent in original production perforation section are used to seal bottom water
                    channeling or coning. For oil wells with thin oil layer (&lt;5m), clear oil-water interface, bottom
                    water lifting and coning caused by upward movement of oil-water interface, new oil-water interface
                    is determined by numerical simulation, new holes are drilled at appropriate positions above new
                    oil-water interface, and selective plugging agent is squeezed from new holes to form artificial
                    partition. Thereby reducing the water content of the oil well produced liquid and increasing the oil
                    production.</div> -->
                </div>
              </div>
            </div>

          </div>
          <div class="screenGpts" style="padding-top: 0;" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;Plugging removal for oil, gas and water wells technology

                </div>
              </div>
            </div>

            <div class="pro3">
              <!--油田-->
              <div class="grand2" @click="enzyme">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect6.png" />
                  </div>
                  <div class="title1">Petroleum enzyme plugging removal technology
                  </div>
                  <!-- <div class="title2">Petroleum enzyme is a kind of bio-based material with catalysis, which can
                    significantly restore the flow capacity of formation crude oil. It is an international leading
                    mature product developed by using modern biological engineering technologies such as genetic
                    engineering, cell engineering, and enzyme engineering to mutate and breed new strains. It has the
                    ability to efficiently release crude oil on the surface of solid particles. It has the
                    characteristics of catalytic oil washing activity, changing rock wettability and reducing
                    interfacial tension.</div> -->

                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="liquid">
                <div class="back">
                  <div class="photo"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect7.png" />
                  </div>
                  <div class="title1">Fluid cavitation stimulation and injection enhancement technology



                  </div>
                  <!-- <div class="title2">The fluid cavitation reservoir reconstruction technology is characterized in that
                    oil pipes are connected with a cavitator, pressure waves such as cavitation shock wave,
                    self-vibration wave and acoustic wave generated by the cavitator are excited by high-speed fluid
                    circulation to act on the reservoir and the fluid in the reservoir together, the liquid in the
                    reservoir pores is excited to generate secondary cavitation, high energy generated by cavitation
                    collapse moment is used to act on pores and throats, a large number of micro-fractures are
                    penetrated and created, fatigue fractures are added, the permeability of the reservoir is improved,
                    and the production increase is realized.</div> -->

                </div>
              </div>
              <!--油田-->
              <div class="grand2" @click="gaswell">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect8.png" />
                  </div>
                  <div class="title1">Gas well plugging removal (water lock removal) technology
                  </div>
                  <!-- <div class="title2">Gas well plugging removal technology is to add water blocking agent into the well,
                    which can reduce the interfacial tension between formation water and rock surface, and has certain
                    dissolution ability to inorganic and organic scale. After adding water blocking agent into tubing,
                    shut in well, and rely on external gas source to suppress the agent into formation. The agent forms
                    a low interfacial tension film on the pore surface, reduces the surface tension of formation water,
                    and reduces the minimum flowing pressure difference and exciting pressure difference. Discharge the
                    accumulated liquid near the well area by venting or directly inputting the liquid into the gas
                    station, and maintain good gas-liquid throughput for a period of time.</div> -->
                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="magnet">
                <div class="back">
                  <div class="photo"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px" src="@/assets/connect9.png" />
                  </div>
                  <div class="title1">Giant magnetic viscosity reduction and wax prevention technology
                  </div>
                  <!-- <div class="title2">Under the action of strong magnetic field, the energy exchange of magnetic field
                    is the result of shear force change in the process of medium flow. The greater the magnetic field
                    intensity, the more the converted magnetic field energy, the greater the stress tensor on the
                    surface of oil flow medium, the greater the shear stress tensor formed, and the more the viscosity
                    of crude oil decreases.
                  </div> -->
                </div>
              </div>
              <!--油田-->
              <div class="grand2" @click="pressure">
                <div class="back">
                  <div class="photo">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect10.png" />
                  </div>
                  <div class="title1">
                    Pressure Reduction and Injection Enhancement in High Pressure Underinjection Wells Technology


                  </div>
                  <!-- <div class="title2">In the production process of water injection wells in sandstone reservoirs,
                    physical plugging caused by such factors as chemical plugging, corrosion scaling plugging, water
                    injection quality plugging, clay swelling plugging, mineral migration plugging, carbonate cement
                    plugging, interfacial tension in reservoirs, wettability, low porosity and low permeability, etc.,
                    is the comprehensive cause of high pressure and low injection in water injection wells.</div> -->

                </div>
              </div>
            </div>
          </div>
          <div class="screenGpts" style="padding-top: 0;background-color: white" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <div class="title">&nbsp;EOR technology

                </div>
              </div>
            </div>
            <div class="pro3">

              <!--油田-->
              <div class="grand2" @click="microorganism">
                <div class="back " style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw">
                    <img loading="lazy" style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect11.png" />
                  </div>
                  <div class="title1">Microbial oil recovery technology


                  </div>
                  <!-- <div class="title2">Microbial enhanced oil recovery technology refers to the use of specific
                    functional bacteria in the reservoir, with crude oil as the carbon source for metabolic growth, in
                    the process of producing proliferation of bacteria, while producing biosurfactants, organic acids,
                    gases, enzymes and other active substances, acting on the reservoir, improving the flow capacity of
                    crude oil, adjusting the wettability of the reservoir, degrading colloidal asphaltenes, etc., acting
                    on the residual oil of the reservoir.It can make the reservoir tend to the growth and proliferation
                    of crude oil, greatly delay the action period, change the wettability of rock and reduce the
                    interfacial tension.</div> -->

                </div>
              </div>
              <!--        油藏-->
              <div class="grand2" @click="gas">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect12.png" /></div>
                  <div class="title1">High temperature mixed gas huff and puff Injection and production technology
                  </div>
                  <!-- <div class="title2">Oxygen in the air is used as an oxidant, diesel oil is used as a combustion agent,
                    and deflagration is carried out under high pressure to generate carbon dioxide and nitrogen gas at
                    high temperature and high pressure. Water is mixed with it for heat exchange, water absorbs heat and
                    evaporates to form water vapor, carbon dioxide and nitrogen are cooled to 200-250 degrees Celsius.
                    Finally, the high temperature gas mixture is injected into the oil layer by using its high pressure.
                  </div> -->

                </div>
              </div>
              <div class="grand2" @click="heavyoil">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect13.png" /></div>
                  <div class="title1">Low temperature emulsification and viscosity reduction of super heavy oil wells
                    technology


                  </div>
                  <!-- <div class="title2">Emulsification viscosity reduction technology is to disperse solid and liquid
                    components in heavy oil by using emulsifier and its oxidation process, and to form heavy
                    oil-emulsifier-surfactant ternary system by introducing low molecular weight surfactant into
                    emulsifier and heavy oil, so as to dissolve heavy oil in emulsifier and change viscosity.</div> -->

                </div>
              </div>
              <div class="grand2" @click="dense">
                <div class="back" style="background-color: #bfd8f9">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw"><img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px"
                      src="@/assets/connect14.png" /></div>
                  <div class="title1">Nano imbibing agent enhanced oil recovery technology


                  </div>
                  <!-- <div class="title2">Ultra-low permeability, shale/tight reservoirs have poor physical properties,
                    generally are fine pore/very fine throat reservoirs, water is difficult to enter nano-pore throat,
                    swept volume is small, and water flooding recovery is low. Nano-imbibition surfactant has small
                    particle size (nanometer level). After injected into reservoir, it can make formation crude oil
                    (especially residual oil) emulsified, stripped and replaced spontaneously from rock through
                    capillary force to form oil-in-water emulsion mixed phase liquid, which reduces fluid viscosity and
                    flow resistance, thus improving oil displacement efficiency.
                  </div> -->
                </div>
              </div>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import { shareUrl } from "@/utils/vxshare";
import temperature from "@/views/groundthrid/temperature.vue";
export default {

  name: "",
  computed: {
    temperature() {
      return temperature
    }
  },

  props: [],

  components: { PartnerTip, consultTip, headtip, bottomTip },

  data() {
    return {
      title: '',
      pageTitle: 'A series of techniques for tapping the potential of old wells combined with geological engineering-工程地质技术GPTs-Geological Engineering GPTsGPT',
      pageDescription: 'Based on fine geological research, geological engineering combined with old well tapping technology uses physical, chemical and biological technologies/materials as means to increase the mobility of formation fluid by removing oil, gas and water flow resistance on the one hand, and optimize crude oil flow profile by "plugging" and "adjusting" on the other hand, so as to improve the oil and gas production degree within the well control range and fully tap the production potential of old wells.',
      pageKeywords: 'A series of techniques for tapping the potential of old wells combined with geological engineering,工程地质技术GPTs,Geological Engineering GPTsGPT',
      model: false,
      dialogconsult: false,
      dialogVisible: false,

    }

  },


  methods: {
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    rhmodie() {
      window.open("/RHsupramolecular")
    },
    temp() {
      window.open("/emperatureresistance")
    },
    necleus() {
      window.open("/core-shell")
    },
    water() {
      window.open("/self-assembling")
    },
    bottom() {
      window.open("/waterreservoir")
    },
    microorganism() {
      window.open("/microbialoil")
    },
    gas() {
      window.open("/hightemperature")
    },
    heavyoil() {
      window.open("/emulsification")
    },
    dense() {
      window.open("/nanoimbibing")
    },
    enzyme() {
      window.open("/petroleumenzyme")
    },
    liquid() {
      window.open("/fluidcavitation")
    },
    gaswell() {
      window.open("/waterlockremoval")
    },
    magnet() {
      window.open("/giantmagnetic")
    },
    pressure() {
      window.open("/pressurereduction")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'A series of techniques for tapping the potential of old wells combined with geological engineering-工程地质技术GPTs-Geological Engineering GPTsGPT',
        pageDescription: 'Based on fine geological research, geological engineering combined with old well tapping technology uses physical, chemical and biological technologies/materials as means to increase the mobility of formation fluid by removing oil, gas and water flow resistance on the one hand, and optimize crude oil flow profile by "plugging" and "adjusting" on the other hand, so as to improve the oil and gas production degree within the well control range and fully tap the production potential of old wells.',
        pageKeywords: 'A series of techniques for tapping the potential of old wells combined with geological engineering,工程地质技术GPTs,Geological Engineering GPTsGPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole {
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 2.84301606922126vh 1.5vw 2.84301606922126vh 5vw;
      background-image: url("../../assets/groundHome6new.png");
      background-position: 100% center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 50%, rgba(198, 211, 239, 0.5));
      }

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;
        width: 50%;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 1.4;
          
        }

        .title2 {
          margin-top: 3vh;
          width: 44vw;
          height: fit-content;
          //text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4vh;
        }

        .titlle3 {
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        //text-align: justify;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        row-gap: 2vh;

        .grand2 {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 20vh;
          }


          .title1 {
            padding: 4vh 0.3vw 4vh 0.3vw;
            opacity: 4;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 9vh;
            font-size: 0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 2.5vh;
          }

          .title2 {
            opacity: 4;
            margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
            //text-align: justify;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.8vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            height: 13.5vh;

          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4 {
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 58vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 3.5248454883vw;
              margin: auto;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1.1709416522vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.9243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 59.87268232385661vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }

}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 560px;
      padding: 2.84301606922126vh 0 2.84301606922126vh 0;
      background-image: url("../../assets/groundHome6new.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 50%, rgba(198, 211, 239, 0.5));
      }

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width: fit-content;
          text-align: center;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }

      .titlle3 {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;

      }

      .heightLight2 {
        margin: 19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        position: relative;
        height: fit-content;
        margin-top: 25px;

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          cursor: pointer;
          width: 98%;
          margin: 0 auto;
          min-height: 250px;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 280px;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 214px;
              opacity: 1;
              background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 340px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -68px -10px;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          ////z-index::: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      //border-radius: 9px;
      padding: 25px 20px;
      background: white;

      .title {
        text-align: center;
        margin: 0 auto;
        height: fit-content;
        opacity: 1;
        font-size: 16px;
        //color:   #3172dd;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 30px;
      }

      .light {
        margin: 15px auto 0 auto;
        ;
        width: 90%;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 22px;
      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 15px;
        row-gap: 0;

        .grand2 {
          cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 15px 0;
          opacity: 1;
          position: relative;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            height: 100%;
            background: #D4E4FF;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
          }


          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #fff;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 34vh;
            img{
              object-fit: contain;
            }
          }


          .title1 {
            opacity: 4;
            width: 90%;
            margin: 15px auto;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: fit-content;
            font-size: 14px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 22px;
          }

          .title2 {
            opacity: 4;
            margin: 1.4em;
            //text-align: justify;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            /* 限制在一个块元素显示的文本的行数 */
            overflow: hidden;
            text-overflow: ellipsis;
            height: 13.5vh;

          }


          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size: 0.80878105141537vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }


      }



    }
  }

}
</style>
