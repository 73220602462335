<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Fiber optic wellbore leakage point <br> real-time prediction platform
              </div>
              <div class="title2">Fiber optic wellbore leakage prediction software integrates advanced sensing technology and deep learning algorithms to provide an efficient and accurate real-time monitoring and early warning solution for the oil and gas industry to ensure wellbore safety and reduce maintenance costs.</div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
                Product Inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake1">
            <div class="heighLight">Solving user pain points</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/frackIcon1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">High Difficulty In Managing And Applying Geological Data And Project Engineering Data</div>
                    <div class="Title2">
                      Oil and gas wellbore monitoring involves multiple data sources such as geological data, engineering data, and environmental data. These data come from different sources and have different formats, making integration difficult. There is a lack of unified data standards and interfaces, resulting in ineffective data fusion and utilization.
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/frackIcon2.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">Limited Expert Engineering Experience Is Not Replicable</div>
                    <div class="Title2">
                      Oil and gas development projects rely relatively heavily on experts/engineers with rich experience in historical engineering projects. This valuable engineering experience is not preserved in the form of knowledge, making it not replicable and unable to cover a large number of projects in a short time.
                    </div>
                  </div>


                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img src="@/assets/frackIcon3.png">
                  </div>
                  <div class="titleDesign">
                    <div class="Title1">Real-time Intelligent Risk Early Warning Is Urgently Needed for Oil And Gas Development</div>
                    <div class="Title2">
                      The entire field of oil and gas engineering development is characterized by high risks. Current monitoring methods lack real-time data analysis and intelligent prediction capabilities, and fail to fully utilize big data and advanced algorithms for leak point prediction and preventive management.
                    </div>
                  </div>


                </div>


              </div>
            </div>

          </div>
          <div class="screenmake2" style="background-color: transparent">
            <div class="heighLight">
              Function introduction</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">DAS Heat Map
                    </div>
                    <div class="title2">
                      After cleaning and organizing the fiber optic detection data, these data are presented in the form of a heat map on the front-end interface, displaying the changes of various parameters within the wellbore in real-time. This heat map not only reflects the operational status of the wellbore in real-time but also supports zooming, dragging, and viewing detailed data for specific depths and time intervals.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back1" >
                  <div class="titleDesign">
                    <div class="title1">DTS Heat Map
                    </div>
                    <div class="title2">
                      After cleaning and organizing the user's data, it is rendered into a heat map on the front-end page, showing real-time data changes. Real-time monitoring of the wellbore status is enabled, with operations such as zooming, dragging, and viewing specific depths and times available.
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back2">

                  <div class="titleDesign">
                    <div class="title1">DAS Leak Detection
                    </div>
                    <div class="title2">
                      Real-time data collected by fiber optic sensors are input into a pre-trained linear regression model to achieve real-time prediction of the wellbore status. Through this prediction, changes in various parameters within the wellbore can be effectively monitored, identifying potential leak points or other anomalies in a timely manner.
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight">Technical Selection</div>

            <div class="heighLight1">Utilizing the linear regression algorithm to swiftly reveal the impact of various parameters on the results, providing a foundation for further analysis and decision-making. It boasts high computational efficiency, making it suitable for real-time or near-real-time analysis of large volumes of data. The regression coefficients directly reflect the marginal contribution of independent variables to the dependent variable, making the model transparent and easily interpretable, facilitating intuitive understanding and optimization adjustments by business personnel and decision-makers.</div>

          </div>
          <div class="screenmake0">
            <div class="heighLight">Functional Experience</div>
            <div class="heighLight1">Based on data collected by underground fiber optic sensors, the data is analyzed and presented with features such as
              <span style="font-weight: bold">heat map display</span>、<span style="font-weight: bold">leak point prediction</span>You can<span style="font-weight: bold;color: red;">use function below, free experience!</span> <br>If you have cooperation intention and product consultation, please<span style="color: #FF6400;cursor: pointer" @click="clickConsult('Geological Engineering GPTs')">contact us</span>。 </div>
            <div class="grid">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="DAS" name="first">
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div style="display: flex">
                          <div class="Title1">
                            1. Data Source：
                          </div>
                          <div class="Title2">
                            Data collected by the fiber optic acquisition box and processed.
                          </div>
                        </div>
                        <div>
                          <div class="Title1">
                            2. Operation Process：<span class="Title2" > ①Click 【Download DAS sample file】 to download DAS sample data; click 【Download DAS sample file】 to upload DAS document data. ②Click 【Generate heat map】、【Predict】, and after a while, processing the data can generate a heat map and prediction results.
                 </span>
                          </div>
                          <div class="Title2" style="display: flex">
                          </div>
                        </div>
                        <div style="display: flex">
                          <!-- <div class="Title1">
                            *&nbsp;  注意事项：
                          </div>
                          <div class="Title2">
                            模型选择对数据分析的准确度至关重要，选择与您上传数据所在区域相关的模型可提高数据分析准确率。
                          </div> -->
                        </div>
                      </div>
                      <div ref="iframeContainer" class="iframeStyle1">
                        <iframe :src="iframeUrl1" frameborder="0" allowfullscreen></iframe> </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="DTS" name="second">
                  <div class="card">
                    <div class="back">
                      <div class="titleDesign">
                        <div style="display: flex">
                          <div class="Title1">
                            1. Data Source：
                          </div>
                          <div class="Title2">
                            Data collected by the fiber optic acquisition box and processed.
                          </div>
                        </div>
                        <div>
                          <div class="Title1">
                            2. Operation Process：<span class="Title2" >①Click 【Download DTS sample file】 to download DTS sample data; click 【Upload DTS folder】 to upload DTS document data. ②Click 【Generate heat map】, and after a while, processing the data can generate a heat map.
                  </span>
                          </div>

                        </div>



                      </div>
                      <div ref="iframeContainer" class="iframeStyle2">
                        <iframe :src="iframeUrl2" frameborder="0" allowfullscreen style="height: 117vh"></iframe>
                      </div>




                    </div>


                  </div>
                </el-tab-pane>
              </el-tabs>


            </div>

          </div>

          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalModel">
        <div class="screen1">
          <headtip @showDialog="showPartner"></headtip>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->

          <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2">
            <div class="title1">Fiber optic wellbore leakage point real-time prediction platform</div>
            <div class="title2">Fiber optic wellbore leakage prediction software integrates advanced sensing technology and deep learning algorithms to provide an efficient and accurate real-time monitoring and early warning solution for the oil and gas industry to ensure wellbore safety and reduce maintenance costs.</div>
          </div>

          <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
            Product Inquiry
          </div>
          <!-- <div class="title4">*油气人工智能模型的泛化能力有限，实际应用中可能需根据具体场景和需求进行适配和优化。如您有合作意向或产品咨询，请随时与我们联系。</div> -->
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <div class="screenmake6">
          <div class="heighLight" style="margin-top:15px">Solving user pain points</div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="title1">
                    High Difficulty In Managing And Applying Geological Data And Project Engineering Data
                  </div>
                  <div class="title2">
                    Oil and gas wellbore monitoring involves multiple data sources such as geological data, engineering data, and environmental data. These data come from different sources and have different formats, making integration difficult. There is a lack of unified data standards and interfaces, resulting in ineffective data fusion and utilization.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="title1">
                    Limited Expert Engineering Experience Is Not Replicable
                  </div>
                  <div class="title2">
                    Oil and gas development projects rely relatively heavily on experts/engineers with rich experience in historical engineering projects. This valuable engineering experience is not preserved in the form of knowledge, making it not replicable and unable to cover a large number of projects in a short time.
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="back">
                <div class="titleDesign">
                  <div class="title1">
                    Real-time Intelligent Risk Early Warning Is Urgently Needed for Oil And Gas Development
                  </div>
                  <div class="title2">
                    The entire field of oil and gas engineering development is characterized by high risks. Current monitoring methods lack real-time data analysis and intelligent prediction capabilities, and fail to fully utilize big data and advanced algorithms for leak point prediction and preventive management.
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div class="screenmake2">
          <div class="heighLight">   <span>Function introduction</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="imgDesign"></div>
                <div class="titleDesign">
                  <div class="imgDesign">  <img src="@/assets/frackIcon1.png"></div>
                  <div>
                    <div class="Title1">DAS Heat Map</div>
                    <div class="Title2">
                      After cleaning and organizing the fiber optic detection data, these data are presented in the form of a heat map on the front-end interface, displaying the changes of various parameters within the wellbore in real-time. This heat map not only reflects the operational status of the wellbore in real-time but also supports zooming, dragging, and viewing detailed data for specific depths and time intervals.
                    </div>
                  </div>

                </div>
                <div class="titleDesign" style="margin-top: 10px">
                  <div class="imgDesign">  <img src="@/assets/frackIcon2.png"></div>
                  <div>
                    <div class="Title1">DTS Heat Map</div>
                    <div class="Title2">
                      After cleaning and organizing the user's data, it is rendered into a heat map on the front-end page, showing real-time data changes. Real-time monitoring of the wellbore status is enabled, with operations such as zooming, dragging, and viewing specific depths and times available.
                    </div>
                  </div>

                </div>
                <div class="titleDesign" style="margin-top: 10px">
                  <div class="imgDesign">  <img src="@/assets/frackIcon3.png"></div>
                  <div>
                    <div class="Title1">DAS Leak Detection</div>
                    <div class="Title2">
                      Real-time data collected by fiber optic sensors are input into a pre-trained linear regression model to achieve real-time prediction of the wellbore status. Through this prediction, changes in various parameters within the wellbore can be effectively monitored, identifying potential leak points or other anomalies in a timely manner.
                    </div>
                  </div>

                </div>
              </div>


            </div>




          </div>
          <div class="heighLight">Technical Selection</div>
          <div class="heighLight1">Utilizing the linear regression algorithm to swiftly reveal the impact of various parameters on the results, providing a foundation for further analysis and decision-making. It boasts high computational efficiency, making it suitable for real-time or near-real-time analysis of large volumes of data. The regression coefficients directly reflect the marginal contribution of independent variables to the dependent variable, making the model transparent and easily interpretable, facilitating intuitive understanding and optimization adjustments by business personnel and decision-makers.</div>
        </div>
        <div class="screenmake2" style="background: #D2E3FF !important;">
          <div class="heighLight">   <span>Functional Experience</span></div>
          <div class="grid">
            <div class="card">
              <div class="back">
                <div class="titleDesign" style="margin-top: 0">
                  <div class="Title2" style="margin-left: 0">
                    Based on data collected by underground fiber optic sensors, the data is analyzed and presented with features such as<span style="color:#FF6400;">heat map display，leak point prediction</span>。You can go to the computer to experience the free functions! <br>Visit link(PC version): &nbsp;<span style="border-bottom: #333333 1px solid">https://cn.ogdri.com/fibernew</span><br>
                    If you have any cooperation or product consultation needs, please <span style="color:red" @click="clickConsult('Geological Engineering GPTs')">contact us</span>！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="bottom">
          <bottomTip></bottomTip>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: {PartnerTip, consultTip, headtip,bottomTip},

  data() {

    return {
      title:"",
      model:false,
      pageTitle: 'Fiber optic wellbore leakage point real-time prediction platform',
      pageDescription: ' Fiber optic wellbore leakage prediction software integrates advanced sensing technology and deep learning algorithms to provide an efficient and accurate real-time monitoring and early warning solution for the oil and gas industry to ensure wellbore safety and reduce maintenance costs. ',
      pageKeywords: 'Fiber optic wellbore leakage point real-time prediction platform,Geological Engineering GPTs',
      dialogconsult:false,   scrollback:false,
      dialogVisible:false,
      activeName: 'first',
      userId:localStorage.getItem("userId"),
      iframeUrl1:"https://ab.oilgasgpts.com/fiberwebEn",
      // iframeUrl1:"http://localhost:8080/fiberweb",
      iframeUrl2:"https://ab.oilgasgpts.com/fiberwebEn/#/dts",
      // iframeUrl2:"http://localhost:8080/fiberweb/#/dts",
      // iframeUrl2:"https://ab.oilgasgpts.com/jyylcshdycjcshfy?type=eur",
      // iframeUrl1:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=correlation",
      // iframeUrl2:"http://54.223.240.188:8686/jyylcshdycjcshfy?type=eur",
      success:[{
        img:require("@/assets/success11.png"),
        title1:"助力长庆油田某采油厂实现地面工程设备设施健康管理",
        title3:"油田地面工程是油气田开发生产这个大系统中的]一个重要子系统，包括油气集输、污水处理、配制注入系统等，由离心泵、柱塞泵、加热炉等核心设备构成。核心设备健康管理水平的高低，!直接影响着油气田开发技术的高低和经济效益。",
      },{
        title1: "助力天津石化设备健康管理",
        title3:"<li>往复式压缩机气阀温度采集无线传感器替代</li><li>实现设备多源参数采集统管理</li><li>实现设备、测点、数据自主配置组态</li><li>实现多维图谱分析工具应用</li>",

      },
      ]
    }

  },

  methods: {
    handleClick(tab, event) {
      // if(this.activeName==="second"){
      //   console.log(this.activeName)
      //   const element = document.querySelector('.iframeStyle iframe');
      //   element.style.height="117vh"
      // }
      console.log(this.activeName);
      console.log(tab, event);
    },

    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    handleScroll() {
      this.scrollHeight = this.$refs.total.scrollTop;


      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 350) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);
    document.title=this.pageTitle;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }
    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  watch:{
    "$store.state.userId"(newValue) {
      this.userId = newValue;

    },
  },
  created() {

    localStorage.setItem("home",0)
    let url = window.location.href
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '基于压裂参数的EUR预测及参数反推平台-工艺类GPTs-油气通GPT',
        pageDescription: ' 利用先进的深度学习技术，对压裂EUR预测相关的工程参数及地质参数进行分析，准确分析不同参数对于最终可采储量的影响，从而为施工过程中的调整做出指导。 ',
        pageKeywords: '基于压裂参数的EUR预测及参数反推平台，压裂EUR预测，油气通GPT',
      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .topback {
    position: fixed;
    z-index: 999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }

  .makeHole1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 70vh;

      padding: 2.84301606922126vh 1.5vw  2.84301606922126vh 5vw;
      background-image: url("../../assets/datainnerbackground.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 1.4;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .background {
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 2.86077411900635vw;
      width: 100%;
      height: 62.41285537700865vh;
      background-image: url("../../assets/third4.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle1 {
        margin-top: 15.09270704573548vh;
        text-align: center;
        font-size: 3.0151935298vw;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 2.84301606922126vh

      }

      .title4 {
        text-align: center;
        margin: 6.82076637824475vh auto 0 auto;
        width: 48vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 4.31520395550062vh;
      }

      .title2 {
        text-align: left;
        margin: 3vh auto 0 auto;
        width: fit-content;
        font-size: 0.9vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 4.31520395550062vh;
      }

      .titlle3 {
        margin: 6vh auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 53px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }

      .titlle3:hover {

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
    }

    .screenmake0 {
      padding: 0.8vh 9vw 2.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }

      .heighLight1 {
        text-align: left;
        margin: 0 auto;
        width: 80vw;
        height: fit-content;
        font-size: 1.2vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 4.5vh;
      }

      ::v-deep .el-tabs__nav {
        text-align: center;
        float: none;
      }

      ::v-deep .el-tabs__item {
        width: 50%;
        font-size: 1.2vw !important;
        padding: 2vh 5vh;
        height: fit-content;
      }

      ::v-deep .el-tabs__active-bar {
        background-color: #316fff;
      }

      ::v-deep .el-tabs__header {

        margin: 0 0 0 0;
      }

      ::v-deep .el-tabs__item.is-active {
        color: #316fff;
      }

      .grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          //padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            //display: flex;
            position: relative;
            //background-color: #ececec;
            padding: 0 0 0 0;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 85vh;;
            overflow: hidden;


            .iframeStyle1 {
              background-color: #ececec;
              margin: -24vh 0 0 -9.5vw;
              width: 100vw;
              transform: scale(0.82);
              //border: #0a0a0a 5px solid;

              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;

              overflow: hidden;
            }
            .iframeStyle2 {
              background-color: #ececec;
              margin: -12vh 0 0 -9.5vw;
              width: 100vw;
              transform: scale(0.82);
              //border: #0a0a0a 5px solid;

              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;

              overflow: hidden;
            }

            .iframeStyle1 iframe {
              background-color: #ececec;
              width: 100vw;
              height: 254vh;
              //height: fit-content;
              //top: 0;
              //left: 0;
              transform-origin: center center;
              overflow: hidden;
            }
            .iframeStyle2 iframe {
              background-color: #ececec;
              width: 100vw;
              height: 254vh;
              //height: fit-content;
              //top: 0;
              //left: 0;
              transform-origin: center center;
              overflow: hidden;
            }

            .imgDesign {

              width: fit-content;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 2vh 3vw;
              width: 100%;
              background-color: #ececec;
              border-top-left-radius: 0;
              border-top-right-radius: 9px;

              .Title1 {

                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: bold;
                line-height: 1.2vw;
              }

              .Title2 {
                color: #111111;
                text-align: justify;
                font-size: 0.8vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 1.2vw;
              }
            }
          }
        }

      }
    }

    .screenmake1 {
      padding: 6.8430160692vh 9vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        text-align: center;
        height: 7.54017305315204vh;
        font-size: 2.2060658579vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        //color: #2168DB;
        line-height: 2.71940667490729vh;
      }
      .heighLight1 {
        text-align: left;
        margin: 0 auto;
        width: 80vw;
        height: fit-content;
        font-size: 1.2vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 4.5vh;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 0;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
          opacity: 1;

          .back {
            border-radius: 9px;
            position: relative;
            border: white 1px solid;
            background-color: #D4E4FF;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 54vh;;
            padding: 0 0 3vh 0;
            overflow: hidden;

            .imgDesign {
              height: 8vh;
              width: 8vh;
              margin: 2vh auto;
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              justify-content: center;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              margin: 0 1vw;

              .Title1 {
                margin: 1vh auto;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: center;
                color: #111111;
                text-align: center;
                font-size: 1vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4.7194066749vh;
              }

              .Title2 {
                display: flex;
                flex-direction: column;
                justify-content: center; /* Center vertically */
                align-items: flex-start;
                color: #111111;
                text-align: justify;
                font-size: 0.9vw;
                height: fit-content;
                font-family: Sarial, sans-serif, "Microsoft Yahei";
                font-weight: 400;
                line-height: 4vh;
              }
            }
          }
        }

      }
    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
      }
      .heighLight1 {
        text-align: left;
        margin: 0 auto;
        width: 65vw;
        height: fit-content;
        font-size: 1.2vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #2168DB;
        line-height: 4.5vh;
      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) ;
        column-gap: 0;
        .card{
          //cursor: pointer;
          width: 92%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .titleDesign{
            z-index: 999;
            padding: 0;
            margin:0 0 0 1.5vw;
            color: black;
            width: 100%;
            float: right;
            .title1{
              margin: 0 auto;
              text-align: center;
              font-size: 1.1709416522vw;
              font-weight: bold;
            }
            .title2{
              z-index: 999;
              margin-top: 2vh;
              text-align: justify;
              line-height: 3.194066749vh;
              font-size: 0.9vw;
            }
            .title3{
              display: grid;
              grid-template-columns: minmax(0,1fr) minmax(0,1fr);

              margin-top: 2vh;
              line-height: 3.194066749vh;
              font-size: 0.8243212016vw;
            }

          }
          .back         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            background-image: url("../../assets/equipmentBack.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            height:36vh ;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow:hidden;
          }
          .back1         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            background-image: url("../../assets/equipmentBack.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            height:36vh ;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow:hidden;


          }
          .back2         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            background-image: url("../../assets/equipmentBack.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            height:36vh ;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow:hidden;


          }
          .back3         {
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            background-image: url("../../assets/equipmentBack.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
            height:36vh ;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow:hidden;


          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 13.097631vw 4.78739184177998vh 13.097631vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        font-weight: bold;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) ;
        column-gap: 1vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:15vh ;
            overflow:hidden;
            .imgDesign{
              width: 5vh;
              height:5vh;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:0 0 0 1.5vw;
              color: black;
              width: 85%;
              float: right;
              .title1{
                margin-top: 0;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 2vh;
                text-align: justify;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}

.totalModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    //z-index: 3;
    position: relative;
    width: 100%;
    height: 460px;

    padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 2.86077411900635vw;
    background-image: url("../../assets/groundinnerbannerM.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .titlle3{
      position: absolute;
      bottom:50px;
      left:50%;
      transform: translateX(-60%);
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 53px;
      width: fit-content;
      margin: 0 15px;
      padding: 10px 25px;
      height: 40px;
      color:white;
      background-color: #2f6eff;
      border: 1px solid #2f6eff;
      overflow: hidden;

    }

    .title4{
      position: absolute;
      left:10%;
      bottom:25px;
      margin: 35px auto 0 auto;
      width: 80%;
      height: 18px;
      font-size: 10px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      line-height: 14px;
    }

    .layer2 {
      // position: absolute;
      // left:0;
      margin-top:20px;
      .title1 {
        margin:0 auto;
        width: fit-content;
        text-align: center;
        height: fit-content;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: white;

        line-height:32px;
      }

      .title2 {
        margin: 20px auto 0 auto;
        width: 90%;
        text-align: left;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: white;
        line-height: 24px;
      }

      .title3 {
        margin: 15px auto;
        width: fit-content;
        text-align: left;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color:white;
        line-height: 24px;
      }
    }

  }
  .screenmake0 {
    padding: 20px 4.6875vw;
    width: 100%;
    height: fit-content;
    background: white;

    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;

    }

    .heightLight2 {

      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid {

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 1vw;

      .card1 {
        //cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0;
        opacity: 1;

        .back {
          border-radius: 9px;
          position: relative;
          height: fit-content;
          overflow: hidden;
          margin-bottom: 1.5248454883vh;

          .imgDesign {
            border-radius: 9px;
            text-align: center;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign {
            padding: 1.5248454883vh 1.5248454883vw;
            //background-color: #2F6EFF;
            .Title1 {
              color: black;
              text-align: center;
              font-size: 1.1709416522vw;

              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight: 700;
            }

          }


          .backconsult{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 50%;
            transform: translateX(-50%);

            color: #2168DB;
          }

          .backconsultArrow{
            position: absolute;
            bottom: 1.81248454882571vh;
            text-align: center;
            font-size: 0.9665511265vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 400;
            color: #2168DB;
            align-items: center; /* Vertically center the text */
            justify-content: center; /* Horizontally center the text */
            height: 2.95414091470952vh;
            left: 80%;
            transform: translateX(-50%);
          }

        }


      }

    }
  }

  .screenmake2{
    padding: 20px 15px;
    width: 100%;
    height: fit-content;
    background:#FDFDFD;
    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }
    .heighLight1 {
      margin: 14px 12px 10px 40px;
      text-align: justify;
      height: fit-content;
      font-size:14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      line-height: 22px;
    }

    .grid{
      margin-top:5px ;
      display: grid;
      grid-template-columns: minmax(0,1fr);
      column-gap:0 ;
      row-gap: 15px;
      .card{
        cursor: pointer;
        width: fit-content;
        height:fit-content ;
        padding:5px 5px;
        opacity: 1;
        .back{
          border-radius: 16px;
          position: relative;
          // box-shadow: 5px 0 14px 0px #D4E4FF;
          height:fit-content;
          overflow:hidden;
          // background: white;
          .imgDesign{
            position:absolute;
            transition:transform 0.3s ease-out;border-radius:9px;
            overflow:hidden;
            width: 100%;
            height: 210px;
            opacity: 1;
            background-image: url("../../assets/fiber1.png");
            background-position: center;
            background-size:contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }

          .titleDesign{
            margin-top: 220px;
            display: flex;
            .imgDesign
            {
              border-radius:9px;   position:absolute;
              transition:transform 0.3s ease-out;
              overflow:hidden;
              width: 30px;
              margin-top: -5px;
              height: 30px;
              opacity: 1;
            }

            .Title1{
              display: flex;
              text-align: left;
              //height: 20px;
              margin-left:40px;
              font-size: 16px;
              font-family: arial, sans-serif, "Microsoft Yahei";
              font-weight:bold;
              color:black;
              line-height: 2.7194066749vh;
            }
            .Title2{
              margin: 14px 12px 10px 40px;
              text-align: justify;
              height: fit-content;
              font-size:14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              line-height: 22px;
            }

            .backconsult{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 50%;
              transform: translateX(-50%);
            }

            .backconsultArrow{
              position: absolute;
              bottom: 15px;
              text-align: center;
              font-size: 14px;
              font-family: Sarial, sans-serif, "Microsoft Yahei";
              font-weight: 400;
              color: #2168DB;
              align-items: center; /* Vertically center the text */
              justify-content: center; /* Horizontally center the text */
              left: 60%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .screenmake6 {
    //z-index:: 10;

    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;
    padding: 20px 4.6875vw;
    background: white;

    .heighLight {
      text-align: center;
      height: 21px;
      opacity: 1;
      font-size: 21px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }

    .heightLight2 {
      margin: 19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 25px;
    }

    .grid {
      position: relative;
      height: fit-content;

      .card {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
        opacity: 1;

        .title1 {
          margin-top: 5px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
        }

        .title2 {

          width: fit-content;
          margin: 2vh;
          line-height: 22px;
          font-size: 14px;
        }

        .titleDesign {
          margin-top: 20px;
        }

        .title3 {
          margin-top: 5px;
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
          line-height: 22px;
          font-size: 14px;
        }

        .back {
          margin-top: 15px;
          height: fit-content;
          background: #EFF5FF;
          border-radius: 9px;
          position: relative;
          box-shadow: 2px 0 12px 0px #c9d9f5;
          //height:580px ;
          overflow: hidden;

          .imgDesign {
            width: 100%;
            height: 195px;
            opacity: 1;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }


        }

      }

      ::v-deep .el-carousel__container {
        height: 65.87268232385661vh !important;
      }

      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }

      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        //z-index:: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }

    .grid1 {
      position: relative;
      height: fit-content;
      display: grid;
      margin-top: 25px;
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 2vw;
      row-gap: 15px;

      .card {
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        height: 185px;
        background-color: white;
        padding: 5px 10px;
        opacity: 1;

        .title1 {
          height: 45px;
          justify-content: center;
          align-items: center;
          display: flex;
          font-size: 16px;
          font-weight: bold;
        }

        .title2 {
          margin: 0 5px;
          text-align: justify;
          line-height: 22px;
          font-size: 14px;
        }

        .titleDesign {
          margin-top: 10px;
        }

        .title3 {
          margin-top: 5px;
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
          line-height: 22px;
          font-size: 14px;
        }

        .back {
          margin-top: 15px;
          border-radius: 9px;
          position: relative;
          //height:580px ;
          overflow: hidden;

          .imgDesign {
            width: 40px;
            height: 38px;
            margin: 0 10px 0 5px;
            opacity: 1;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: transparent;
          }


        }

      }

      ::v-deep .el-carousel__container {
        height: 65.87268232385661vh !important;
      }

      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__container {
        position: relative;
        height: 48.83559950556242vh;
      }

      ::v-deep .el-carousel__arrow {
        display: none;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0;
        width: 1.15540150202195vw;
        height: 4.32632880098888vh;
        //background: #2168DB;
        color: #fff;
        position: absolute;
        top: 50%;
        //z-index:: 10;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .screenmake3{
    //left: 10.86077411900635vw;
    width: 100vw;
    height: fit-content;


    padding: 20px 15px;
    background: black;

    .heighLight {
      text-align: center;
      height: 21px;

      opacity: 1;
      font-size: 21px;
      color: white;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 26px;
    }

    .heightLight2{
      margin:19px 2vw;
      text-align: justify;
      width: fit-content;
      height: fit-content;
      font-size:14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 20px;
    }

    .grid{
      margin-top:2.78739184177998vh ;
      position: relative;

      .card{
        cursor: pointer;
        width: 98%;
        margin:0 auto;
        min-height: 250px ;
        padding:0 0.69324090121317vw 0 0;
        opacity: 1;
        background: #19202e;
        .back{
          border-radius: 9px;
          position: relative;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          min-height: 360px ;
          overflow:hidden;
          background: #19202e;
          .imgDesign{
            width: 100%;
            height:200px;
            opacity: 1;
            background-position: center;
            background-size:cover;
            background-repeat: no-repeat;
            background-color: transparent;
          }
          .titleDesign{
            padding: 15px 20px 25px 20px;
            color: white;
            width:100%;
            float: right;
            .title1{
              text-align: center;
              margin-bottom:10px;
              font-size: 16px;
            }
            .title2{
              height: 180px;
              // line-height: 20px;
              font-size:12px;
              ::v-deep li{
                margin-top:5px;
              }
            ;
            }


          }

        }
      }

      ::v-deep .el-carousel__container{
        height: 430px !important;

      }
      .el-carousel__item h3 {
        color: white;
        opacity: 0.75;
        margin: 0;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: transparent;
      }

      .el-carousel__item:nth-child(2n+1) {
        background-color: transparent;
      }

      ::v-deep .el-carousel__arrow {
        display: block;
        //border: 1px solid white;
        outline: -5px;
        padding: 0;

        margin: -68px -10px  ;
        cursor: pointer;

        border-radius: 0;
        width: 25px;
        height: 48px;
        background: rgba(245, 246, 250, 0.8);
        color: #131313;
        position: absolute;
        top: 50%;
        //z-index: 100;
        transform: translateY(-50%);
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
</style>
